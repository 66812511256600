import { useParams } from "react-router";
import Loadable from "react-loadable";
import { useQuery } from "@apollo/client";
import { QUERY_STOCK_HISTORIES } from "../queries";
import usePaginationState from "../../customhooks/usePaginationHook";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const StockHistoryListComponent: any = Loadable({
  loader: () => import("../../components/Stock/StockListHistory"),
  loading: () => <TableLoader />,
});

const StockHistoryList = () => {
  const param = useParams();
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const { data, loading: stockHistoryLoading } = useQuery(
    QUERY_STOCK_HISTORIES,
    {
      variables: {
        product: param?.id,
        first: pageSize,
        offset: offset,
      },
    },
  );
  return (
    <div>
      <StockHistoryListComponent
        data={data?.purchaseHistories?.edges ?? []}
        setPageSize={setPageSize}
        pageSize={pageSize}
        setOffset={setOffset}
        offset={offset}
        stockHistoryLoading={stockHistoryLoading}
      />
    </div>
  );
};

export default StockHistoryList;
