import { useEffect, useState } from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_SETTINGS } from "../../pages/Settings/graphql";
import { CREATE_UPDATE_BASIC_SETTING } from "../../pages/mutations";
import {
  QUERY_PRINTER,
  QUERY_SERVICE_CHARGES,
  QUERY_TAX,
} from "../../pages/queries";
import { toast } from "react-toastify";

const BasicSettingComponent: any = Loadable({
  loader: () => import("../../components/Settings/SettingList/BasicSetting"),
  loading: DataLoadSpinner,
});

type Props = {};

const Setting = (props: Props) => {
  const { data: basicSetting } = useQuery(QUERY_SETTINGS);
  const settings = basicSetting?.basicWebsiteConfigurations;
  const [printerDropdown, setPrinterDropdown] = useState<any>();
  const [taxDropdown, setTaxDropdown] = useState<any>();
  const [serviceChargeDropdown, setServiceChargeDropdown] = useState<any>();

  const [createUpdateWebsiteConfigurations] = useMutation(
    CREATE_UPDATE_BASIC_SETTING,
    {
      refetchQueries: [
        {
          query: QUERY_SETTINGS,
        },
      ],
    }
  );

  const { data: printerData } = useQuery(QUERY_PRINTER, {
    variables: {
      first: 100,
    },
  });
  // QUERY_SERVICE_CHARGES
  const { data: taxData } = useQuery(QUERY_TAX, {
    variables: {
      first: 100,
    },
  });
  const { data: serviceChargeData } = useQuery(QUERY_SERVICE_CHARGES, {
    variables: {
      first: 100,
    },
  });

  useEffect(() => {
    const dropdownFormat: any = [];
    printerData?.printers?.edges?.map((elem: any) => {
      return dropdownFormat.push({
        id: elem.node.pk,
        title: elem.node.name,
      });
    });
    setPrinterDropdown(dropdownFormat);
  }, [printerData]);

  //  TAX/VAT DROPDOWN
  useEffect(() => {
    const dropdownFormat: any = [];
    if (taxData) {
      taxData?.taxes?.edges?.map((elem: any) =>
        dropdownFormat.push({
          id: elem?.node?.pk,
          title: elem?.node?.title,
          rate: elem?.node?.rate,
          isIncluded: elem?.node?.isIncluded,
        })
      );
      dropdownFormat.push({
        id: 0,
        title: "GST Free",
        rate: 0,
        isIncluded: null,
      });
    }
    setTaxDropdown(dropdownFormat);
  }, [taxData]);

  //SERVICE CHARGE DROPDOWN
  useEffect(() => {
    const dropdownFormat: any = [];
    serviceChargeData?.serviceCharges?.edges?.map((elem: any) => {
      return dropdownFormat.push({
        id: elem.node.pk,
        title: elem.node.title,
      });
    });
    setServiceChargeDropdown(dropdownFormat);
  }, [serviceChargeData]);

  const handleBasicSettingUpdate = async (e: any, name: String) => {
    switch (name) {
      case "timezone":
        await createUpdateWebsiteConfigurations({
          variables: {
            timezone: e,
          },
        });
        break;
      case "currency":
        await createUpdateWebsiteConfigurations({
          variables: {
            currency: e,
          },
        });
        break;
      case "country":
        await createUpdateWebsiteConfigurations({
          variables: {
            country: e,
          },
        });
        break;
      // NO END POINT YET
      case "default_vat":
        await createUpdateWebsiteConfigurations({
          variables: {
            taxRate: e,
          },
        });
        break;
      case "default_service_charge":
        await createUpdateWebsiteConfigurations({
          variables: {
            serviceCharge: e,
          },
        });
        break;
      case "default_email_server":
        await createUpdateWebsiteConfigurations({
          variables: {
            des: e,
          },
        });
        break;

      case "virtual_keyboard":
        await createUpdateWebsiteConfigurations({
          variables: {
            isVirtualKeyboard: e,
          },
        });
        break;
      // NO ENDPOINT YET
      case "compound_product":
        await createUpdateWebsiteConfigurations({
          variables: {
            country: e,
          },
        });
        break;
      case "websiteTitle":
        await createUpdateWebsiteConfigurations({
          variables: {
            title: e,
          },
        });
        break;
      case "logo":
        await createUpdateWebsiteConfigurations({
          variables: {
            logo: e,
          },
        });
        break;
      case "faviconIcon":
        await createUpdateWebsiteConfigurations({
          variables: {
            faviconIcon: e,
          },
        });
        break;
      case "print_Product_By_Category_Order":
        await createUpdateWebsiteConfigurations({
          variables: {
            printProductByCategoryOrder: e,
          },
        });
        break;
      case "stock_alert":
        await createUpdateWebsiteConfigurations({
          variables: {
            isStockMaintain: e,
          },
        });
        break;
      case "auto_print":
        await createUpdateWebsiteConfigurations({
          variables: {
            isAutoPrint: e,
          },
        });
        break;
      case "product_wise_print":
        await createUpdateWebsiteConfigurations({
          variables: {
            isProductWisePrint: e,
          },
        });
        break;
      case "no_of_columns":
        await createUpdateWebsiteConfigurations({
          variables: {
            noOfColumns: e,
          },
        });
        break;
      case "resetOrder":
        const response: any = await createUpdateWebsiteConfigurations({
          variables: {
            resetOrder: e,
          },
        });
        if (response?.data?.createUpdateWebsiteConfigurations?.success) {
          toast.success("Successfully reset");
        } else {
          toast.error("Error!!");
        }
        break;
        
      case "qrOrderUrl":
        await createUpdateWebsiteConfigurations({
          variables: {
            qrOrderUrl: e,
          },
        });
        break;
      default:
        toast.warning("invalid setting");
        break;
    }
  };
  return (
    <>
      <BasicSettingComponent
        settings={settings}
        handleBasicSettingUpdate={handleBasicSettingUpdate}
        printerDropdown={printerDropdown}
        taxDropdown={taxDropdown}
        serviceChargeDropdown={serviceChargeDropdown}
      />
    </>
  );
};

export default Setting;
