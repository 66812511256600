import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../../components/localstate/Cache";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { DELETE_RESTAURANT_TABLE } from "../../mutations";
import { QUERY_RESTAURANT_TABLE } from "../../queries";
import TableLoader from "../../../components/reusable/Loader/TableLoader";
import { QUERY_SETTINGS } from "../../Settings/graphql";
import Spinner from "../../../components/reusable/Spinner";

const RestaurantTableListComponent: any = Loadable({
  loader: () =>
    import("../../../components/RestaurantTable/Table/RestaurantTableList"),
  loading: () => <TableLoader />,
});

type Props = {};

const RestaurantTable = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);
  const { data: settingData, loading: querySettingLoading } =
    useQuery(QUERY_SETTINGS);
  const { data: RestaurantTableData, loading: restaurantTableLoading } =
    useQuery(QUERY_RESTAURANT_TABLE, {
      variables: {
        first: pageSize,
        offset: offset,
        title_Icontains: searchItems?.searchInput,
        subTable_Isnull: true,
        isVisible: true,
        isFunctional: true,
      },
    });

  const [deleteRestaurantTable] = useMutation(DELETE_RESTAURANT_TABLE, {
    refetchQueries: [
      {
        query: QUERY_RESTAURANT_TABLE,
        variables: {
          first: pageSize,
          offset: offset,
          title_Icontains: searchItems?.searchInput,
          subTable_Isnull: true,
          isVisible: true,
        },
      },
    ],
  });

  const handleDeleteRestaurantTable = async (id: string) => {
    const response = await deleteRestaurantTable({
      variables: {
        objectId: id,
      },
    });
    if (response.data?.deleteRestaurantTable?.success) {
      toast.success(response.data?.deleteRestaurantTable?.message);
    } else {
      toast.error(
        response.data?.deleteRestaurantTable?.errors?.nonFieldErrors[0]?.message
      );
    }
  };

  return (
    <>
      {querySettingLoading ? (
        <Spinner/>
      ) : (
        <RestaurantTableListComponent
          data={RestaurantTableData?.restaurantTables}
          handleDeleteRestaurantTable={handleDeleteRestaurantTable}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setOffset={setOffset}
          offset={offset}
          restaurantTableLoading={restaurantTableLoading}
          settingData={settingData}
        />
      )}
    </>
  );
};

export default RestaurantTable;
