import DashboardIcon from "../../src/assets/sidebarIcons/white/dashboard.svg";
import AccountIcon from "../../src/assets/sidebarIcons/white/account.svg";
import CategoryIcon from "../../src/assets/sidebarIcons/white/category.svg";
import ProductIcon from "../../src/assets/sidebarIcons/white/product.svg";
import PosIcon from "../../src/assets/sidebarIcons/white/pos.svg";
import TableIcon from "../../src/assets/sidebarIcons/white/table.svg";
import StockIcon from "../../src/assets/sidebarIcons/white/stock.svg";
import UnitIcon from "../../src/assets/sidebarIcons/white/unit.svg";
import ServiceChargeIcon from "../../src/assets/sidebarIcons/white/servicecharge.svg";
import PrinterIcon from "../../src/assets/sidebarIcons/white/print.svg";
import DiscountIcon from "../../src/assets/sidebarIcons/white/discount.svg";
import OrderIcon from "../../src/assets/order.svg";
import RemoteOrderIcon from "../../src/assets/remoteorder.svg";

import CartIcon from "../../src/assets/cart.svg";

import LedgerIcon from "../../src/assets/sidebarIcons/white/ledger.svg";
import TransactionIcon from "../../src/assets/sidebarIcons/white/transactions.svg";
import SupplierIcon from "../../src/assets/sidebarIcons/white/supplier.svg";
import CustomerIcon from "../../src/assets/sidebarIcons/white/customer.svg";

import ProductListIcon from "../../src/assets/sidebarIcons/white/productlist.svg";
import ModifierListIcon from "../../src/assets/sidebarIcons/white/modifierlist.svg";

import ReportIcon from "../../src/assets/sidebarIcons/white/report.svg";
import OpeningClosingIcon from "../../src/assets/sidebarIcons/white/openingclosing.svg";
import QuickSalesReportIcon from "../../src/assets/sidebarIcons/white/quicksalesreport.svg";

import SettingIcon from "../../src/assets/sidebarIcons/white/setting.svg";
import BasicSettingIcon from "../../src/assets/sidebarIcons/white/basicsetting.svg";
import ReceiptSettingIcon from "../../src/assets/sidebarIcons/white/receiptsetting.svg";

import ExpensesIcon from "../../src/assets/ExpensesReport.svg";
import CashOutIcon from "../../src/assets/CashoutReport.svg";
import RefundIcon from "../../src/assets/RefundReport.svg";
import DiscountReport from "../../src/assets/discountReport.svg";
import VoidReportIcon from "../../src/assets/void order.svg";
import RewardsProgramReport from "../../src/assets/RewardProgramReport.svg";

import DetailsSalesReport from "../../src/assets/DetailsSalesReport.svg";
import HourlySalesReport from "../../src/assets/HourlySalesReport.svg";
const routerObject = [
  {
    name: "Dashboard",
    icon: DashboardIcon,
    path: "/",
    children: [],
  },
  {
    name: "Promotion",
    icon: DashboardIcon,
    path: "/promotions",
    children: [],
  },

  {
    name: "Account",
    icon: AccountIcon,
    path: null,
    children: [
      {
        name: "Ledger",
        icon: LedgerIcon,
        path: "/account",
        children: [],
      },
      {
        name: "Customer",
        icon: CustomerIcon,
        path: "/customer",
        children: [],
      },
      {
        name: "Supplier",
        icon: SupplierIcon,
        path: "/supplier",
        children: [],
      },
      {
        name: "Transaction",
        icon: TransactionIcon,
        path: "/transactions",
        children: [],
      },
    ],
  },
  {
    name: "Category",
    icon: CategoryIcon,
    path: "/category",
    children: [],
  },
  {
    name: "Product",
    icon: ProductIcon,
    path: null,
    children: [
      {
        name: "Product",
        icon: ProductListIcon,
        path: "/product",
        children: [],
      },
      {
        name: "Modifier",
        icon: ModifierListIcon,
        path: "/modifier",
        children: [],
      },
    ],
  },
  {
    name: "Unit",
    icon: UnitIcon,
    path: "/unit",
    children: [],
  },
  {
    name: "Table",
    icon: TableIcon,
    path: null,
    children: [
      {
        name: "Table",
        icon: TableIcon,
        path: "/restaurant-table",
        children: [],
      },
      {
        name: "Floor",
        icon: TableIcon,
        path: "/restaurant-floor",
        children: [],
      },
    ],
  },
  {
    name: "Discount",
    icon: DiscountIcon,
    path: "/pos-discount",
    children: [],
  },
  {
    name: "Orders",
    icon: OrderIcon,
    path: null,
    children: [
      {
        name: "Hold Cart",
        icon: CartIcon,
        path: "/hold-cart",
        children: [],
      },
      {
        name: "Remote Orders",
        icon: RemoteOrderIcon,
        path: "/remote-orders",
        children: [],
      },
      {
        name: "Orders",
        icon: OrderIcon,
        path: "/orders",
        children: [],
      },
      {
        name: "Transactions",
        icon: TransactionIcon,
        path: "/transactions",
        children: [],
      },
    ],
  },
  {
    name: "Product Print Orders",
    icon: OrderIcon,
    path: "/product-print-orders",
    children: [],
  },
  {
    name: "Stock",
    icon: StockIcon,
    path: "/stock",
    children: [],
  },
  {
    name: "GST",
    icon: ServiceChargeIcon,
    path: "/tax",
    children: [],
  },
  {
    name: "Service Charge",
    icon: ServiceChargeIcon,
    path: "/servicecharge",
    children: [],
  },
  {
    name: "Order Type",
    icon: ServiceChargeIcon,
    path: "/order-type",
    children: [],
  },
  {
    name: "Printer",
    icon: PrinterIcon,
    path: "/printer",
    children: [],
  },
  {
    name: "Report",
    icon: ReportIcon,
    path: null,
    children: [
      {
        name: "Sales Dashboard",
        icon: OpeningClosingIcon,
        path: "/sales-report",
        children: [],
      },
      {
        name: "Detailed Sales Report",
        icon: DetailsSalesReport,
        path: "/detail-sales-report",
        children: [],
      },
      {
        name: "Hourly Sales Report",
        icon: HourlySalesReport,
        path: "/hourly-sales-report",
        children: [],
      },
      {
        name: "Quick Sales Report",
        icon: QuickSalesReportIcon,
        path: "/quick-sales-report",
        children: [],
      },
      {
        name: "Most Selling Products Report",
        icon: QuickSalesReportIcon,
        path: "/productwise-report",
        children: [],
      },
      {
        name: "Most Selling Categories Report",
        icon: QuickSalesReportIcon,
        path: "/categorywise-report",
        children: [],
      },
      {
        name: "Sales By User",
        icon: QuickSalesReportIcon,
        path: "/userwise-report",
        children: [],
      },
      {
        name: "Sales By Floor",
        icon: QuickSalesReportIcon,
        path: "/floorwise-report",
        children: [],
      },
      {
        name: "Sales By Table",
        icon: QuickSalesReportIcon,
        path: "/tablewise-report",
        children: [],
      },
      {
        name: "Opening & Closing",
        icon: OpeningClosingIcon,
        path: "/opening-&-closing-report",
        children: [],
      },

      // {
      //   "name": "Detail Sales Report",
      //   "icon": DetailSalesReportIcon,
      //   "path": "/detail-sales-report",
      //   "children": []
      // },
      // {
      //   "name": "Employee Hour",
      //   "icon": EmployeeHourIcon,
      //   "path": "/employee-hour-report",
      //   "children": []
      // },
      // {
      //   "name": "Stock",
      //   "icon": StockIcon,
      //   "path": "/stock-report",
      //   "children": []
      // },
      // {
      //   "name": "Members",
      //   "icon": MembersIcon,
      //   "path": "/members-report",
      //   "children": []
      // },
      // {
      //   "name": "Wastage & Loss Report",
      //   "icon": WastageLossIcon,
      //   "path": "/wastage-loss-report",
      //   "children": []
      // },
      // {
      //   "name": "No Sale",
      //   "icon": NoSaleIcon,
      //   "path": "/no-sale-report",
      //   "children": []
      // }
    ],
  },
  {
    name: "Terminal",
    icon: UnitIcon,
    path: "/terminal",
    children: [],
  },
  {
    name: "ExpensesReport",
    icon: ExpensesIcon,
    path: null,
    children: [
      {
        name: "Refund",
        icon: RefundIcon,
        path: "/refund",
        children: [],
      },
      {
        name: "CashOut",
        icon: CashOutIcon,
        path: "/cashout",
        children: [],
      },
      {
        name: "Void Order/Company Expenses",
        icon: VoidReportIcon,
        path: "/void-order-expenses",
        children: [],
      },
      {
        name: "Discount",
        icon: DiscountReport,
        path: "/discountexpenses",
        children: [],
      },
      {
        name: "RewardsProgram",
        icon: RewardsProgramReport,
        path: "/reward-programs",
        children: [],
      },
    ],
  },
  {
    name: "Setting",
    icon: SettingIcon,
    path: null,
    children: [
      {
        name: "Basic Setting",
        icon: BasicSettingIcon,
        path: "/basic-setting",
        children: [],
      },
      {
        name: "Reward Setting",
        icon: BasicSettingIcon,
        path: "/reward-setting",
        children: [],
      },
      {
        name: "Receipt Setting",
        icon: ReceiptSettingIcon,
        path: "/receipt-setting",
        children: [],
      },
      {
        name: "Sync Setting",
        icon: BasicSettingIcon,
        path: "/sync-setting",
        children: [],
      },
    ],
  },
];

export default routerObject;
