import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import Loadable from "react-loadable";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { GET_CATEGORY, QUERY_CATEGORY } from "../queries";
import { DELETE_CATEGORY, UPDATE_CATEGORY } from "../mutations";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import usePaginationHook from "../../customhooks/usePaginationHook";
import { searchItemsVar } from "../../components/localstate/Cache";

type Props = {};

const EditCategoryComponent: any = Loadable({
  loader: () => import("../../components/Category/EditCategory"),
  loading: DataLoadSpinner,
});

const EditCategory = (props: Props) => {
  const { id: selectedCategoryID } = useParams();
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();

  const navigate = useNavigate();

  const { data: selectedCategoryData, loading: loadingCategory } = useQuery(
    GET_CATEGORY,
    {
      variables: {
        id: selectedCategoryID,
        first: pageSize,
        offset: offset,
      },
    }
  );
  const [updateCateogory, { loading: updateCateogoryLoading }] = useMutation(
    UPDATE_CATEGORY,
    {
      refetchQueries: [
        {
          query: QUERY_CATEGORY,
          variables: {
            first: pageSize,
            level: 0,
          },
        },
        {
          query: GET_CATEGORY,
          variables: {
            id: selectedCategoryID,
            first: pageSize,
            offset: offset,
          },
        },
      ],
    }
  );

  const handleEditCategory = async (values: any, props: any) => {
    const response = await updateCateogory({
      variables: {
        pk: selectedCategoryData?.category.pk,
        name: values?.name,
        parent: selectedCategoryData?.category?.parent?.pk,
        status: values?.status ? "modifier" : "visible",
        image: typeof values?.image === "string" ? null : values?.image,
      },
    });
    if (response?.data?.updateCategory?.success) {
      navigate(`/category`);
      props.resetForm();
      toast.success("Edited Category");
    } else {
      const errors = backendErrorDisplay(
        response?.data?.updateCategory?.errors
      );
      toast.error(
        response?.data?.updateCategory?.errors?.nonFieldErrors[0]?.message
      );
      props.setErrors(errors);
    }
  };

  return (
    <>
      <EditCategoryComponent
        loadingCategory={loadingCategory}
        handleEditCategory={handleEditCategory}
        selectedCategoryData={selectedCategoryData}
        updateCateogoryLoading={updateCateogoryLoading}
      />
    </>
  );
};

export default EditCategory;
