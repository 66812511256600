//@ts-nocheck
import { useState } from "react";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../customhooks/usePaginationHook";
import AccessControl from "../../helpers/accessControl";
import UserPermissionRequired from "../Error/UserPermisssionRequired";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  searchCustomer,
  searchItemsVar,
} from "../../components/localstate/Cache";
import { PRODUCT_LIST } from "./queries";
import { POS_SCREEN_DELETE_PRODUCT } from "./mutation";
import apolloClient, { DOWNLOAD_HOSTNAME } from "../../apolloClient";
import { toast } from "react-toastify";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import { QUERY_CATEGORY } from "../queries";
import Loadable from "react-loadable";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const ProductListComponent: any = Loadable({
  loader: () => import("../../components/Product/ProductList/ProductList"),
  loading: () => <TableLoader showTotal={true} />,
});
const Product = (props: any) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const [selected, setSelected] = useState({
    pk: "",
    id: "",
    name: "",
  });
  const [categoryId, setCategoryId] = useState<any>();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [nullCategory, setNullCategory] = useState(false);

  // SEARCH FOR PRODUCT
  const searchItems: any = useReactiveVar(searchItemsVar);
  const checkCategoryIsNull: any = nullCategory
    ? {
        variables: {
          first: pageSize,
          offset: offset,
          isModifier: false,
          compound_Isnull: true,
          isOpenProduct: false,
          search: searchItems?.searchInput ?? "",
          categoryList: selected?.id ? selected?.id : [],
          category_Isnull: true,
        },
        fetchPolicy: "network-only",
      }
    : {
        variables: {
          first: pageSize,
          offset: offset,
          isModifier: false,
          compound_Isnull: true,
          isOpenProduct: false,
          search: searchItems?.searchInput ?? "",
          categoryList: selected?.id ? selected?.id : [],
        },
        fetchPolicy: "network-only",
      };
  const searchCustomerVar = useReactiveVar(searchCustomer);
  const { data: posScreenProducts, loading: productsLoading } = useQuery(
    PRODUCT_LIST,
    checkCategoryIsNull
  );
  // DELETE FOR POS
  const [deleteProduct, { loading: loadingDeleteProduct }] = useMutation(
    POS_SCREEN_DELETE_PRODUCT,
    {
      refetchQueries: [
        {
          query: PRODUCT_LIST,
          variables: {
            first: pageSize,
            offset: offset,
            isModifier: false,
            search: searchItems?.searchInput ?? "",
            categoryList: categoryId ? [categoryId] : [],
          },
        },
      ],
      fetchPolicy: "network-only",
      awaitRefetchQueries: true,
    }
  );
  const handleDeleteProduct = async (id: any) => {
    const response = await deleteProduct({
      variables: {
        objectId: id,
      },
    });
    if (response?.data?.deleteProduct?.success) {
      await apolloClient.refetchQueries({
        include: "active",
      });
      toast.success("Product Deleted successfully");
    } else {
      const errors = backendErrorDisplay(response?.data?.deleteProduct?.errors);
      toast.error(errors);
    }
  };

  const { data: rootCategoriesData, loading: categoryLoading } = useQuery(
    QUERY_CATEGORY,
    {
      variables: {
        first: pageSize,
        level: 0,
        offset: offset,
        name_Icontains: searchCustomerVar ?? "",
      },
    }
  );
  let filterCategories: any = [];
  rootCategoriesData?.categories?.edges?.map((item: any) => {
    filterCategories.push({
      id: item?.node?.id,
      pk: item?.node?.pk,
      name: item?.node?.name,
    });
  });
  return (
    <AccessControl
      allowedPermissions={["can_view_product", "can_edit_product"]}
      renderNoAccess={<UserPermissionRequired />}
      children={
        <ProductListComponent
          data={posScreenProducts?.products ?? []}
          handleDeleteProduct={handleDeleteProduct}
          setPageSize={setPageSize}
          pageSize={pageSize}
          setOffset={setOffset}
          offset={offset}
          loadingDeleteProduct={loadingDeleteProduct}
          rootCategoriesData={rootCategoriesData}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
          isExportLoading={isExportLoading}
          filterCategories={filterCategories}
          searchCustomerVar={searchCustomerVar}
          searchCustomer={searchCustomer}
          selected={selected}
          setSelected={setSelected}
          setNullCategory={setNullCategory}
          nullCategory={nullCategory}
          productsLoading={productsLoading}
        />
      }
    />
  );
};

export default Product;
