import { gql } from "@apollo/client";
export const POS_SCREEN_COMPOUND_PRODUCT = gql`
  query products(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $search: String
    $categoryList: [ID]
    $isModifier: Boolean
    $compound_Isnull: Boolean
  ) {
    products(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title: $search
      category: $categoryList
      isModifier: $isModifier
      compound_Isnull: $compound_Isnull
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          title
          barcodeContent
          isModifier
          productStocks {
            availableQuantity
          }
          plu
          isActive

          tax {
            id
            pk
            title
            rate
          }
          isWeightable
          unit {
            title
          }
          finalPrice
          costPrice
          sellingPrice
          image

          category {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const POS_SCREEN_PRODUCT = gql`
  query products(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $search: String
    $categoryList: [ID]
    $isModifier: Boolean
    $compound_Isnull: Boolean
    $isOpenProduct: Boolean
  ) {
    products(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title: $search
      category: $categoryList
      isModifier: $isModifier
      compound_Isnull: $compound_Isnull
      isOpenProduct: $isOpenProduct
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          title
          plu
          isModifier
          productStocks {
            availableQuantity
          }
          barcodeContent
          isActive
          tax {
            id
            pk
            title
            rate
          }
          isWeightable
          unit {
            title
          }
          finalPrice
          costPrice
          sellingPrice
          image
          category {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const PRODUCT_LIST = gql`
  query products(
    $first: Int
    $after: String
    $before: String
    $search: String
    $offset: Int
    $isModifier: Boolean
    $categoryList: [ID]
    $isOpenProduct: Boolean
    $isLessThanThreshold: String
  ) {
    products(
      first: $first
      after: $after
      before: $before
      offset: $offset
      isModifier: $isModifier
      title: $search
      category: $categoryList
      isOpenProduct: $isOpenProduct
      isLessThanThreshold: $isLessThanThreshold
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          pk
          id
          title
          barcodeContent
          isModifier
          displayInWebsite
          tax {
            id
            pk
            title
            rate
          }
          finalPrice
          image
          productStocks {
            availableQuantity
            threshold
          }
          category {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const POS_SCREEN_UPDATE_PROMOTION = gql`
  mutation (
    $id: String!
    $tax: String
    $compound: String
    $sellingPrice: String
  ) {
    updateProduct(
      input: {
        id: $id
        compound: $compound
        tax: $tax
        sellingPrice: $sellingPrice
      }
    ) {
      errors
      success
      product {
        pk
        id
        title
        finalPrice
        sellingPrice
        tax {
          id
          pk
          title
        }
        compound {
          title
          pk
          id
          finalPrice
        }
      }
    }
  }
`;
export const POS_SCREEN_SINGLE_PRODUCT = gql`
  query product($id: ID!, $category_Status: String) {
    product(id: $id) {
      pk
      id
      title
      plu
      productStocks {
        availableQuantity
      }
      barcodeContent
      isActive
      isModifierCategoryGrid
      isOpenPriceProduct
      isScalable
      displayInWebsite
      tax {
        id
        pk
        title
        isIncluded
        rate
      }
      isWeightable
      printOrder {
        pk
        name
      }
      shortDesc
      longDesc
      finalPrice
      sellingPrice
      costPrice
      image
      unit {
        id
        pk
        title
      }
      compound {
        title
        pk
        id
        finalPrice
      }
      printer {
        edges {
          node {
            pk
            id
            name
          }
        }
      }
      modifierCategories(category_Status: $category_Status) {
        edges {
          node {
            id
            pk
            category {
              name
              pk
            }
          }
        }
      }
      category {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
    }
  }
`;

export const POS_SCREEN_PRODUCT_CATEGORY = gql`
  query QUERY_CATEGORY($first: Int, $level: Int, $status: String) {
    categories(first: $first, level: $level, status: $status) {
      edges {
        node {
          id
          pk
          name
          status
          parent {
            id
            pk
            name
          }
          children(first: 100) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const POS_SCREEN_PRINTER_LIST = gql`
  query printers($first: Int) {
    printers(first: $first) {
      edges {
        node {
          id
          pk
          name
          department
          location
          port
        }
      }
    }
  }
`;

export const ASSIGN_MODIFIER_CATEGORY = gql`
  mutation ($id: String!, $category: [String]) {
    assignModifierCategory(input: { id: $id, category: $category }) {
      errors
      success
    }
  }
`;

export const CREATE_PRODUCT_VARIANT = gql`
  mutation CREATE_PRODUCT_VARIANT($attributeIds: [String]!, $product: String) {
    createProductVariant(
      input: { attributeIds: $attributeIds, product: $product }
    ) {
      success
      errors
      productVariant {
        id
        pk
        product {
          title
          id
          pk
        }
      }
    }
  }
`;

export const GET_VARIANT_PRODUCT = gql`
  query GET_VARIANT_PRODUCT($id: ID!) {
    productVariant(id: $id) {
      id
      pk
      name
      attributes
      contentTypeId
      finalPrice
      price {
        costPrice
        sellingPrice
      }
      getStockQuantity
      media {
        id
        media
      }
      getSku
      getBarcode
      getPlu
      tracking {
        barcodeContent
        qrCodeContent
        plu
        trackingCode
      }
    }
  }
`;

export const DELETE_VARIANT_PRODUCT = gql`
  mutation DELETE_PRODUCT_VARIANT($objectId: String!) {
    deleteProductVariant(input: { objectId: $objectId }) {
      success
      errors
    }
  }
`;

export const EDIT_VARIANT_PRODUCT = gql`
  mutation EDIT_PRODUCT_VARIANT(
    $id: String!
    $attributeIds: [String]!
    $product: String
  ) {
    updateProductVariant(
      input: { id: $id, attributeIds: $attributeIds, product: $product }
    ) {
      success
      errors
    }
  }
`;

export const QUERY_COLLECTIONS = gql`
  query QUERY_COLLECTIONS($first: Int, $offset: Int) {
    collections(first: $first, offset: $offset) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          name
          start
          end
          isVisible
          contentTypeId
        }
      }
    }
  }
`;
