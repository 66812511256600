import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import usePaginationState from "../../customhooks/usePaginationHook";
import {
  BULK_PURCHASE_STOCK,
  MANAGE_STOCK,
  PURCHASE_STOCK,
} from "../mutations";
import { QUERY_SUPPLIER } from "../queries";
import {
  searchCustomer,
  searchItemsVar,
} from "../../components/localstate/Cache";
import { POS_SCREEN_PRODUCT, PRODUCT_LIST } from "../Product/queries";
import UserPermissionRequired from "../Error/UserPermisssionRequired";
import AccessControl from "../../helpers/accessControl";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const StockListComponent: any = Loadable({
  loader: () => import("../../components/Stock/StockList"),
  loading: () => <TableLoader />,
});

type Props = {};

const StockList = (props: Props) => {
  const [openPurchase, setOpenPurchase] = useState<boolean>(false);
  const [openBulkPurchaseStock, setOpenBulkPurchaseStock] =
    useState<boolean>(false);
  const [openBulkProductStock, setOpenBulkProductStock] =
    useState<boolean>(false);
  const [openManageStock, setOpenManageStock] = useState<boolean>(false);

  const [bulkTableData, setBulkTableData] = useState<any>([]);
  const [manageBulkStockData, setManageBulkStockData] = useState<any>([]);
  const [bulkTableTotalPrice, setBulkTableTotalPrice] = useState<any>(0);
  const [isLessThanThreshold, setIsLessThanThreshold] = useState<string>("");

  const [productId, setProductId] = useState<string>("");
  const [productPrice, setProductPrice] = useState<string>("");
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);
  const [input, setInput] = useState<any>();
  const [inputSuppliers, setInputSuppliers] = useState<any>();
  const { data: stockData, loading: stockLoading } = useQuery(PRODUCT_LIST, {
    variables: {
      first: pageSize,
      offset: offset,
      search: searchItems?.searchInput,
      isModifier: false,
      isOpenProduct: false,
      isLessThanThreshold: isLessThanThreshold,
    },
    fetchPolicy: "network-only",
  });

  const [purchaseStock, { loading: purchaseStockLoading }] = useMutation(
    PURCHASE_STOCK,
    {
      refetchQueries: [
        {
          query: PRODUCT_LIST,
          variables: {
            first: pageSize,
            offset: offset,
            name: searchItems?.searchInput,
          },
        },
      ],
    }
  );

  const [bulkPurchaseStock, { loading: bulkPurchaseStockLoading }] =
    useMutation(BULK_PURCHASE_STOCK, {
      refetchQueries: [
        {
          query: PRODUCT_LIST,
          variables: {
            first: pageSize,
            offset: offset,
            name: searchItems?.searchInput,
          },
        },
      ],
    });

  const [manageStock, { loading: manageStockLoading }] = useMutation(
    MANAGE_STOCK,
    {
      refetchQueries: [
        {
          query: PRODUCT_LIST,
          variables: {
            first: pageSize,
            offset: offset,
            name: searchItems?.searchInput,
          },
        },
      ],
    }
  );

  // SUPPLIER DROPDOWN
  const { data: supplierData } = useQuery(QUERY_SUPPLIER, {
    variables: {
      search: inputSuppliers,
      first: 10,
      offset: 0,
    },
    fetchPolicy: "network-only",
  });

  const supplierList: any = [];
  supplierData?.suppliers?.edges?.map((elem: any) =>
    supplierList.push({
      id: elem?.node?.pk,
      title: elem?.node?.user?.fullName,
    })
  );
  const searchCustomerVar = useReactiveVar(searchCustomer);
  // PROUDUCT DROPDOWN
  const { data: productData } = useQuery(POS_SCREEN_PRODUCT, {
    variables: {
      isModifier: false,
      isOpenProduct: false,
      search: searchCustomerVar,
      first: 10,
      offset: 0,
    },
    fetchPolicy: "network-only",
  });

  const productList: any = [];
  productData?.products?.edges?.map((elem: any) =>
    productList.push({
      id: elem?.node?.pk,
      title: elem?.node?.title,
      costPrice: elem?.node?.costPrice,
    })
  );

  const handleOpen = (props: any) => {
    setOpenPurchase(true);
    setProductId(props?.row?.original?.node?.pk);
    setProductPrice(props?.row?.original?.node?.costPrice);
  };

  const handlePurchaseStock = async (values: any, props: any) => {
    const response = await purchaseStock({
      variables: {
        supplier: values?.supplier,
        product: productId,
        costPrice: values?.costPrice,
        quantity: values?.quantity,
        totalCostPrice: (values?.costPrice * values?.quantity).toFixed(2),
        discount: values?.discount,
        amountAfterDiscount:
          (values?.costPrice * values?.quantity - values?.discount).toFixed(
            2
          ) ?? 0.0,
        taxAmount:
          (parseFloat(values?.taxAmount || 0) / 100) *
          (values?.costPrice * values?.quantity - values?.discount),
        cpAfterTax: (
          values?.costPrice * values?.quantity -
          values?.discount +
          (parseFloat(values?.taxAmount || 0) / 100) *
            (values?.costPrice * values?.quantity - values?.discount)
        ).toFixed(2),
      },
    });
    if (response?.data?.createPurchaseHistory?.success) {
      toast.success("Purchased Stock Successfully");
      setOpenPurchase(false);
    } else {
      let errors = backendErrorDisplay(
        response.data?.createPurchaseHistory?.errors
      );
      props.setErrors(errors);
    }
  };

  const handleAddBulkPurchaseStock = (e: any, formikProps: any) => {
    const bulkProduct = {
      amountAfterDiscount: (
        e?.costPrice * e?.quantity - e?.discount ?? 0.0
      ).toFixed(2),
      costPrice: e?.costPrice,
      cpAfterTax: (
        e?.costPrice * e?.quantity -
        e?.discount +
        (parseFloat(e?.taxAmount || 0) / 100) *
          (e?.costPrice * e?.quantity - e?.discount)
      ).toFixed(2),
      discount: e.discount,
      product: e.product,
      quantity: e.quantity,
      supplier: e.supplier,
      taxAmount: e.taxAmount,
      totalCostPrice: e?.costPrice * e?.quantity ?? 0.0,
    };
    setBulkTableData((prev: any) => [...prev, bulkProduct]);
    formikProps.resetForm();
    setInput("");
    setInputSuppliers("");
  };

  useEffect(() => {
    if (bulkTableData.length > 0) {
      let tempVar = bulkTableData
        .map((elem: any) => parseFloat(elem.cpAfterTax).toFixed(2))
        .reduce(
          (partialSum: any, a: any) => parseFloat(partialSum) + parseFloat(a)
        );
      setBulkTableTotalPrice(parseFloat(tempVar).toFixed(2));
    }
  }, [bulkTableData]);

  const handleUploadBulkStockData = async () => {
    if (bulkTableData.length === 0) {
      toast.error("Add atleast one product to purchase stock");
      return;
    }
    const list = bulkTableData.map((elem: any) => {
      return {
        amountAfterDiscount: elem.amountAfterDiscount,
        costPrice: elem.costPrice,
        cpAfterTax: elem.cpAfterTax,
        discount: elem.discount ? elem.discount : 0,
        product: elem.product.id,
        quantity: elem.quantity,
        supplier: elem.supplier.id,
        taxAmount: elem.taxAmount ? elem.taxAmount : 0,
        totalCostPrice: elem.totalCostPrice,
      };
    });
    const response = await bulkPurchaseStock({
      variables: {
        purchasedItems: list,
      },
    });
    if (response?.data?.bulkCreatePurchaseHistory?.success) {
      toast.success("Purchased Stock Successfully");
      setOpenBulkPurchaseStock(false);
      searchCustomer("");
      setBulkTableData([]);
      setBulkTableTotalPrice(0);
    } else {
      backendErrorDisplay(response.data?.bulkCreatePurchaseHistory?.errors);
    }
  };

  const handleAddManageStock = async (value: any, formikProps: any) => {
    const bulkManageStock = {
      product: value.product,
      quantity: value.quantity,
      note: value.note,
    };
    setManageBulkStockData((prev: any) => [...prev, bulkManageStock]);
    setInput("");
    formikProps.resetForm();
  };

  const handleUploadManageStock = async () => {
    if (manageBulkStockData.length === 0) {
      toast.error("Add atleast one product to purchase stock");
      return;
    }
    const list = manageBulkStockData.map((elem: any) => {
      return {
        product: elem.product?.id,
        quantity: elem.quantity,
        // "note": elem.note
      };
    });
    const response = await manageStock({
      variables: {
        products: list,
      },
    });
    if (response?.data?.bulkStockOut?.success) {
      toast.success("Purchased Stock Successfully");
      setOpenManageStock(false);
      setManageBulkStockData([]);
    } else {
      backendErrorDisplay(response.data?.bulkStockOut?.errors);
    }
  };

  return (
    <div>
      <AccessControl
        allowedPermissions={["can_view_stock", "can_edit_stock"]}
        renderNoAccess={<UserPermissionRequired />}
        children={
          <StockListComponent
            data={stockData?.products}
            handleOpen={handleOpen}
            setIsLessThanThreshold={setIsLessThanThreshold}
            isLessThanThreshold={isLessThanThreshold}
            openPurchase={openPurchase}
            openBulkPurchaseStock={openBulkPurchaseStock}
            setOpenBulkPurchaseStock={setOpenBulkPurchaseStock}
            setOpenPurchase={setOpenPurchase}
            openBulkProductStock={openBulkProductStock}
            setOpenBulkProductStock={setOpenBulkProductStock}
            handleAddBulkPurchaseStock={handleAddBulkPurchaseStock}
            handleUploadBulkStockData={handleUploadBulkStockData}
            openManageStock={openManageStock}
            setOpenManageStock={setOpenManageStock}
            productId={productId}
            productPrice={productPrice}
            handlePurchaseStock={handlePurchaseStock}
            supplierList={supplierList}
            productList={productList}
            stockLoading={stockLoading}
            bulkTableData={bulkTableData}
            handleAddManageStock={handleAddManageStock}
            handleUploadManageStock={handleUploadManageStock}
            manageBulkStockData={manageBulkStockData}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setOffset={setOffset}
            offset={offset}
            manageStockLoading={manageStockLoading}
            purchaseStockLoading={purchaseStockLoading}
            bulkPurchaseStockLoading={bulkPurchaseStockLoading}
            bulkTableTotalPrice={bulkTableTotalPrice}
          />
        }
      />
    </div>
  );
};

export default StockList;
